export const SUBSCRIPTION_CONVERSION_MOCKS = [
    ['Wed Apr 03 2024', 1, 0],
    ['Thu Apr 04 2024', 3, 1],
    ['Fri Apr 05 2024', 10, 5],
    ['Sat Apr 06 2024', 12, 6],
    ['Sun Apr 07 2024', 14, 7],
    ['Mon Apr 08 2024', 16, 11],
    ['Tue Apr 09 2024', 20, 11],
    ['Wed Apr 10 2024', 24, 12],
    ['Thu Apr 11 2024', 27, 18],
    ['Fri Apr 12 2024', 30, 20],
    ['Sat Apr 13 2024', 40, 21],
    ['Sun Apr 14 2024', 55, 23],
    ['Mon Apr 15 2024', 56, 24],
    ['Tue Apr 16 2024', 60, 27],
    ['Wed Apr 17 2024', 64, 28],
    ['Thu Apr 18 2024', 70, 30],
    ['Fri Apr 19 2024', 75, 31],
    ['Sat Apr 20 2024', 80, 35],
    ['Sun Apr 21 2024', 86, 35],
    ['Mon Apr 22 2024', 90, 37],
    ['Tue Apr 23 2024', 110, 38],
    ['Wed Apr 24 2024', 115, 41],
    ['Thu Apr 25 2024', 125, 45],
    ['Fri Apr 26 2024', 128, 48],
];
export const TOTAL_INCOME_MOCKS = [
    ['Mon Apr 01 2024', 0, 0],
    ['Tue Apr 02 2024', 0, 0],
    ['Wed Apr 03 2024', 1420.329, 0],
    ['Thu Apr 04 2024', 1560.912, 0],
    ['Fri Apr 05 2024', 1560.912, 0],
    ['Sat Apr 06 2024', 1560.912, 0],
    ['Sun Apr 07 2024', 1710.496, 0],
    ['Mon Apr 08 2024', 1710.496, 0],
    ['Tue Apr 09 2024', 1710.496, 0],
    ['Wed Apr 10 2024', 1780.787, 126],
    ['Thu Apr 11 2024', 1780.787, 126],
    ['Fri Apr 12 2024', 1780.787, 126],
    ['Sat Apr 13 2024', 1860.079, 126],
    ['Sun Apr 14 2024', 1860.079, 126],
    ['Mon Apr 15 2024', 1860.079, 126],
    ['Tue Apr 16 2024', 2000.662, 126],
    ['Wed Apr 17 2024', 2000.662, 126],
    ['Thu Apr 18 2024', 2000.662, 126],
    ['Fri Apr 19 2024', 2150.246, 126],
    ['Sat Apr 20 2024', 2150.246, 126],
    ['Sun Apr 21 2024', 2150.246, 126],
    ['Mon Apr 22 2024', 2290.829, 126],
    ['Tue Apr 23 2024', 2430.829, 226],
    ['Wed Apr 24 2024', 2430.829, 226],
    ['Thu Apr 25 2024', 2430.829, 226],
    ['Fri Apr 26 2024', 2440.5, 226],
];
export const TOTAL_ASSETS_MOCKS = [
    ['Wed Apr 03 2024', 1, 0],
    ['Thu Apr 04 2024', 3, 1],
    ['Fri Apr 05 2024', 10, 5],
    ['Sat Apr 06 2024', 12, 6],
    ['Sun Apr 07 2024', 14, 7],
    ['Mon Apr 08 2024', 16, 11],
    ['Tue Apr 09 2024', 20, 11],
    ['Wed Apr 10 2024', 24, 12],
    ['Thu Apr 11 2024', 27, 18],
    ['Fri Apr 12 2024', 30, 20],
    ['Sat Apr 13 2024', 40, 21],
    ['Sun Apr 14 2024', 55, 23],
    ['Mon Apr 15 2024', 56, 24],
    ['Tue Apr 16 2024', 60, 27],
    ['Wed Apr 17 2024', 64, 28],
    ['Thu Apr 18 2024', 70, 30],
    ['Fri Apr 19 2024', 75, 31],
    ['Sat Apr 20 2024', 80, 35],
    ['Sun Apr 21 2024', 86, 35],
    ['Mon Apr 22 2024', 90, 37],
    ['Tue Apr 23 2024', 110, 38],
    ['Wed Apr 24 2024', 115, 41],
    ['Thu Apr 25 2024', 125, 45],
    ['Fri Apr 26 2024', 128, 48],
];
