export const getAbsValues = (data) => data.map((element) => [new Date(element[0]), element[1], element[2]]);
export const getDefaultGraphRange = () => {
    const currentDate = new Date(Date.now());
    const firstDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 31));
    const intermediateDate = new Date(new Date().setDate(new Date(currentDate).getDate() - 15));
    return [
        [currentDate, 1, 0],
        [intermediateDate, 1, 0],
        [firstDate, 1, 0],
    ];
};
