import styled from '@emotion/styled';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { BodyMedium, BodyMediumSemiBold, BodySmallSemiBold } from '@src/components/styled/Typography';
export const IncomeGraphContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.xm}px`,
    borderRadius: 6,
    backgroundColor: '#F8F9FA',
    gap: props.theme.spacing_sizes.xs * 0.5,
    position: 'relative',
}));
export const GraphDetailsTitle = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.primary,
    letterSpacing: '-0.096px',
    lineHeight: '19px',
}));
export const GraphDetailsPeriod = styled(BodySmallSemiBold)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '15px',
}));
export const GraphDetails = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
export const GraphDetailsUnit = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '17px',
}));
export const GRAPH_WIDTH = 276;
export const GRAPH_HEIGHT = 163;
export const GRAPH_CURVE_WIDTH = 247;
export const GRAPH_CURVE_HEIGHT = 136;
