import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import Table, { tableRendererWrapper } from '@shared/ui/display/Table';
import TransactionTypeCell from '../table-components/TransactionTypeCell';
import TransactionStatusCell from '../table-components/TransactionStatusCell';
import TransactionAmountCell from '../table-components/TransactionAmountCell';
import PaymentMethodCell from '../table-components/PaymentMethodCell';
import TransactionDateCell from '../table-components/TransactionDateCell';
import ExpandRowCell from '../table-components/ExpandRowCell';
const getColumnsDefinition = (translateFn) => ([
    {
        id: 'date',
        header: translateFn('balance.transaction_history_table.columns.date'),
        enableSorting: true,
        meta: {
            flex: 0.2,
        },
        accessorFn: (model) => model.date,
        accessorKey: 'date',
        cell: tableRendererWrapper(TransactionDateCell),
    },
    {
        id: 'type',
        header: translateFn('balance.transaction_history_table.columns.type.title'),
        meta: {
            flex: 0.22,
        },
        accessorKey: 'type',
        accessorFn: (model) => model.type,
        enableSorting: true,
        cell: tableRendererWrapper(TransactionTypeCell),
    },
    {
        id: 'status',
        header: translateFn('balance.transaction_history_table.columns.status.title'),
        meta: {
            flex: 0.17,
        },
        accessorKey: 'status',
        accessorFn: (model) => model.status,
        enableSorting: true,
        cell: tableRendererWrapper(TransactionStatusCell),
    },
    {
        id: 'amount',
        header: translateFn('balance.transaction_history_table.columns.amount'),
        meta: {
            flex: 0.15,
        },
        accessorKey: 'amount',
        accessorFn: (model) => model.amount,
        enableSorting: true,
        cell: tableRendererWrapper(TransactionAmountCell),
    },
    {
        id: 'payment_method',
        header: translateFn('balance.transaction_history_table.columns.payment_method.title'),
        meta: {
            flex: 0.31,
        },
        accessorFn: (model) => model.details,
        enableSorting: true,
        cell: tableRendererWrapper(PaymentMethodCell),
    },
    {
        id: 'expand_row',
        accessorKey: 'expand',
        header: undefined,
        meta: {
            isExpandTrigger: true,
            flex: 0.08,
        },
        cell: tableRendererWrapper(ExpandRowCell),
    }
]);
const TransactionHistoryTable = ({ data, isLoading = false, }) => {
    const { t } = useTranslation();
    const columnsDef = useMemo(() => getColumnsDefinition(t), [t]);
    const theme = useTheme();
    return (_jsx(Table, { columnDef: columnsDef, data: data, isLoading: isLoading, defaultSkeletonRows: 10, skeletonStyles: {
            marginBottom: theme.spacing_sizes.m,
        }, rowStyles: {
            height: 'auto',
            minHeight: 56,
        }, hideHeader: isLoading, expandable: true }));
};
export default TransactionHistoryTable;
