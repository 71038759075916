import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useAppSelector } from '@app/store/Hooks';
import PageFrameContainer from '@shared/ui/layout/PageFrameContainer';
import { useGetTransactionsHistoryQuery } from '@shared/api/current-user';
import { useGetTraderActivityQuery } from '@shared/api/profile/api';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import ClientPlatformBalanceHeader from './ClientPlatformBalanceHeader';
import TraderPlatformBalanceHeader from './TraderPlatformBalanceHeader';
import TraderPlatformBalanceBottom from './TraderPlatformBalanceBottom';
const PlatformBalance = () => {
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const theme = useTheme();
    const { isLoading: isLoadingHistory, data: transactionHistory, } = useGetTransactionsHistoryQuery();
    const { isLoading: isLoadingActivity, data: traderActivityData, } = useGetTraderActivityQuery(undefined, { skip: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) !== 'TRADER' });
    return (_jsx(SkeletonGroup, { isLoading: isLoadingHistory || isLoadingActivity, children: _jsx(PageFrameContainer, { header: _jsxs(_Fragment, { children: [(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT' && (_jsx(ClientPlatformBalanceHeader, { isLoading: isLoadingHistory, data: transactionHistory })), (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER' && (_jsx(TraderPlatformBalanceHeader, { activityData: traderActivityData, isLoadingActivity: isLoadingActivity, isLoadingManagementData: isLoadingActivity, totalIncome: 0, totalOutcome: 0 }))] }), gap: theme.spacing_sizes.xs * 10, bottom: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER' && (_jsx(TraderPlatformBalanceBottom, { isLoading: isLoadingHistory, data: transactionHistory })) }) }));
};
export default PlatformBalance;
