import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import ManagementIncome from './ManagementIncome';
import { SUBSCRIPTION_CONVERSION_MOCKS, TOTAL_ASSETS_MOCKS, TOTAL_INCOME_MOCKS } from './mocks';
import { getAbsValues, getDefaultGraphRange } from './utils';
import ActivityTable from './ActivityTable';
const TraderPlatformBalanceHeader = ({ isLoadingActivity = false, isLoadingManagementData = false, totalIncome = 0, totalOutcome = 0, activityData = [], }) => {
    const { subscriptionConversion, totalIncome: totalIncomeData, totalAssets, } = useMemo(() => ({
        subscriptionConversion: {
            values: getAbsValues(SUBSCRIPTION_CONVERSION_MOCKS),
            defaultRange: getDefaultGraphRange()
        },
        totalIncome: {
            values: getAbsValues(TOTAL_INCOME_MOCKS),
            defaultRange: getDefaultGraphRange()
        },
        totalAssets: {
            values: getAbsValues(TOTAL_ASSETS_MOCKS),
            defaultRange: getDefaultGraphRange(),
        },
    }), []);
    return (_jsxs(_Fragment, { children: [_jsx(ManagementIncome, { incomeData: totalIncomeData.values, conversionData: subscriptionConversion.values, assetsData: totalAssets.values, conversionDataDefaultRange: subscriptionConversion.defaultRange, incomeDataDefaultRange: totalIncomeData.defaultRange, assetsDataDefaultRange: totalAssets.defaultRange, totalIncome: totalIncome, totalOutcome: totalOutcome, isLoading: isLoadingManagementData }), _jsx(ActivityTable, { isLoading: isLoadingActivity, activityData: activityData })] }));
};
export default TraderPlatformBalanceHeader;
